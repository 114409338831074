import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from '@/components/Layout';
import {
  BrandsGallery,
  Banners,
  FooterBanners,
  CategoriesBanners
} from '@/components/organisms';
import ServicesSlider from '@/components/organisms/ServicesSlider';
import ProductSlider from '@/components/organisms/ProductSlider';
import BrandsLogoSlider from '@/components/organisms/BrandsLogoSlider';
import BannerSlider from '@/components/organisms/BannerSlider';
import MaxContainer from '@/components/atoms/MaxContainer';
import PopUp from '@/components/atoms/PopUp';
import getDataBrands from '@/brands';
import useTrackingQueue from '@/hooks/useTrackingQueue';
import LazyComponent from '@/components/molecules/LazyComponent';
import productsAPI from '@/api/products';
import brandsAPI from '@/api/brands';
import { getCookies } from '@/services/cookies';
import { Streaming } from '@/components/molecules';

const Home = ({ liveInfo }) => {
  const banners = useSelector((state) => state.banners);
  const [dataFetched, setDataFetched] = useState({
    carouselOfProducts: { newnessProducts: [], promotedProducts: [] },
    brands: 'loading'
  });
  const storeId = useSelector((state) => state.store);
  const { storeTitle } = getDataBrands(storeId);
  const categories = useSelector((state) => state.navBar.categories);
  const { executeEvent } = useTrackingQueue();
  const fetchData = async () => {
    const { token } = getCookies();
    const [newnessProducts, promotedProducts, brands] = await Promise.all([
      productsAPI.getNewnessProducts(token, storeId),
      productsAPI.getPromotedProducts(token, storeId),
      brandsAPI.get(storeId)
    ]);

    setDataFetched({
      carouselOfProducts: {
        newnessProducts: newnessProducts.success ? newnessProducts.data : [],
        promotedProducts: promotedProducts.success ? promotedProducts.data : []
      },
      brands: brands.success ? brands.data : []
    });
  };
  useEffect(() => {
    fetchData();
    executeEvent('viewHome');
  }, [storeId]);

  return (
    <Layout
      isHomePage
      description="Descubre nuestra amplia gama de productos deportivos que te ayudarán a alcanzar tus metas y disfrutar al máximo de tu pasión por el deporte."
      h1InFooter={storeTitle}
    >
      <MaxContainer>
        <PopUp />

        {liveInfo.live && <Streaming />}
        {banners?.sliders?.length > 0 && (
          <BannerSlider
            border
            priority
            banners={banners.sliders}
          />
        )}
        {(dataFetched.brands === 'loading' ||
          dataFetched.brands?.length > 0) && (
          <BrandsLogoSlider
            items={dataFetched.brands === 'loading' ? [] : dataFetched.brands}
          />
        )}
        {storeId === 'vaypol' && banners?.secondary?.length > 0 && (
          <Banners
            banners={banners.secondary}
            priority
          />
        )}
        {storeId === process.env.NEXT_PUBLIC_STORE_ID ? (
          <BrandsGallery />
        ) : (
          <CategoriesBanners categories={categories} />
        )}
        <LazyComponent distance="-10px">
          {dataFetched.carouselOfProducts.newnessProducts?.length > 0 && (
            <ProductSlider
              withDots
              withoutChevrons
              withLinkToGallery="lanzamientos"
              title="Novedades"
              titleTag="h2"
              items={dataFetched.carouselOfProducts.newnessProducts}
            />
          )}

          {dataFetched.carouselOfProducts.promotedProducts?.length > 0 && (
            <ProductSlider
              withoutChevrons
              withDots
              title="Ofertas"
              titleTag="h2"
              withLinkToGallery="ofertas"
              items={dataFetched.carouselOfProducts.promotedProducts}
            />
          )}
          {(banners?.tertiary?.length > 0 ||
            banners?.quaternary?.length > 0) && (
            <div
              style={{
                marginBlock: '7rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem'
              }}
            >
              {banners?.tertiary?.length > 0 && (
                <FooterBanners
                  banners={banners.tertiary}
                  withFilter
                />
              )}
              {banners?.quaternary?.length > 0 && (
                <FooterBanners banners={banners.quaternary} />
              )}
            </div>
          )}
        </LazyComponent>
        <ServicesSlider />
      </MaxContainer>
    </Layout>
  );
};

Home.propTypes = {
  liveInfo: PropTypes.object.isRequired
};

Home.defaultProps = {};

export default Home;
