import PropTypes from 'prop-types';
import Slider from '@/components/molecules/Slider';
import { Image, Link } from '@/components/atoms';
import styles from './brandLogoSlider.module.scss';

const BrandsLogoSlider = ({ items }) => (
  <div className={styles.container}>
    <Slider
      color="black"
      withChevrons
      withoutBackgroundChevron
    >
      {items.map((i) => (
        <div
          className={styles.item}
          key={i.name}
        >
          <Link
            href={`/${i.url}${i.url.includes('/p/1') ? '' : '/p/1'}`}
            noWidth
          >
            <div className={styles.img}>
              <Image
                alt={i.name}
                src={i.brand_icon?.url || i.brand_icon_white?.url}
                width={90}
                height={40}
                heightSkeleton="40px"
                widthSkeleton="100px"
                skeletonDark
                cover
              />
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  </div>
);

BrandsLogoSlider.propTypes = {
  items: PropTypes.array.isRequired
};

export default BrandsLogoSlider;
