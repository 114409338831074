import PropTypes from 'prop-types';
import cn from 'classnames';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './productSlider.module.scss';
import { Divider, Link, Text } from '@/components/atoms';
import Slider from '@/components/molecules/Slider';
import { ProductGalleryCard } from '@/components/molecules';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const ProductSlider = (props) => {
  const {
    title,
    items,
    openModalOnClick,
    withLinkToGallery,
    titleTag,
    withDots,
    withoutChevrons
  } = props;

  const { isDesktop } = useDeviceType();
  const titleClasses = cn({
    [styles.title]: true,
    [styles.withLink]: withLinkToGallery
  });
  return (
    <div className={styles.container}>
      <div className={titleClasses}>
        <Text
          textColor="var(--color-white-absolute)"
          textStyle={{
            textAlign: 'center',
            marginBottom: '10px'
          }}
          variant={titleTag}
          textSize="l"
        >
          {title}
        </Text>
        {!isDesktop && !withLinkToGallery && <Divider />}
        {withLinkToGallery && (
          <Link
            href={`/productos/o/${withLinkToGallery}/p/1`}
            primary
          >
            Ver todo
          </Link>
        )}
      </div>
      <Slider
        hightlight
        withDots={withDots}
        withoutChevrons={withoutChevrons}
      >
        {items.map((i) => (
          <ProductGalleryCard
            key={i.url}
            productId={i.id}
            featured={i.featured}
            Link
            productUrl={`${i.url}`}
            openModalOnClick={openModalOnClick}
            isFavorite={i.is_wish_product}
            imageSrc={i.dummy_images[0].url}
            cardContent={{
              gender: i.gender,
              installments: `${i.all_prices.installments.quantity} x $${
                i.all_prices.installments.value.split(',')[0]
              } s/int.`,
              price: i.all_prices.sale_price
                ? i.all_prices.sale_price
                : i.all_prices.original,
              strikePrice: i.all_prices.sale_price ? i.all_prices.original : '',
              name: i.name,
              discount: i.all_prices.discount,
              brandIcon: i.brand_icon,
              brandName: i.brand_name
            }}
            text="Creemos en tu espíritu ganador. Descubrí acá esos productos running ideales para vos."
          />
        ))}
      </Slider>
    </div>
  );
};

ProductSlider.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  openModalOnClick: PropTypes.bool,
  withLinkToGallery: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span']),
  withDots: PropTypes.bool,
  withoutChevrons: PropTypes.bool
};
ProductSlider.defaultProps = {
  openModalOnClick: false,
  withLinkToGallery: '',
  titleTag: 'h3',
  withDots: false,
  withoutChevrons: false
};

export default ProductSlider;
