import { useEffect, useState } from 'react';
import { Image } from '@/components/atoms';
import { GeneralModal } from '@/components/molecules';

const PopUp = () => {
  const date = new Date();
  const [available, setAvailable] = useState(false);
  useEffect(() => {
    setAvailable(!JSON.parse(sessionStorage.getItem('popUpShown') || 'false'));
  }, []);

  if (
    date.getHours() < 20 &&
    date.getDate() === 18 &&
    date.getMonth() === 11 &&
    date.getFullYear() === 2023 &&
    available
  ) {
    return (
      <div>
        <GeneralModal
          onClose={() => {
            setAvailable(false);
            sessionStorage.setItem('popUpShown', 'true');
          }}
          closeColor="white"
          modalStyles={{
            background: 'white',
            height: '340px',
            width: '340px',
            borderRadius: '10px',
            closeStyles: {
              background: 'black',
              borderRadius: '10px',
              opacity: '0.7',
              top: '0px',
              right: '0px'
            }
          }}
        >
          <div style={{ background: 'red' }}>
            <Image
              imageStyles={{ borderRadius: '10px' }}
              src="/assets/popup.png"
              fill
            />
          </div>
        </GeneralModal>
      </div>
    );
  }
  return null;
};
export default PopUp;
