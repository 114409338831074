import { useRef } from 'react';

const useDebounce = (time = 500) => {
  const debounceRef = useRef();
  return (cb) => {
    if (debounceRef) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      (async function () {
        try {
          cb();
          // eslint-disable-next-line no-empty
        } catch (err) {}
      })();
    }, time);
  };
};

export default useDebounce;
