import axios from '../../services/axiosInstance';
import errorHandler from '@/services/error/handler';

const get = async (storeId) => {
  try {
    const response = await axios.get(`/brands?code=${storeId}`);
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    const message = errorHandler(err);

    return {
      success: false,
      error: message
    };
  }
};

export default {
  get
};
