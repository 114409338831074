import Home from '@/components/templates/Home';
import getInitialReduxState from '@/services/page/getInitialReduxState';
import isDesktopServerSide from '@/utils/isDesktopServerSide';

const HomePage = (props) => <Home {...props} />;

export default HomePage;

export async function getServerSideProps(ctx) {
  const isDesktop = isDesktopServerSide(ctx);

  const initialReduxState = await getInitialReduxState('vaypol', {
    faqs: true,
    help: true,
    banners: true,
    navbar: true,
    newsletter: false,
    isDesktop
  });
  return {
    props: {
      initialReduxState
    }
  };
}
