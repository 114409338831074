import { BsCreditCard, BsTruck } from 'react-icons/bs';
import { FaPercent } from 'react-icons/fa';
import { BiLockAlt } from 'react-icons/bi';
import useDeviceType from '@/hooks/useDeviceType';
import { Divider, Text } from '@/components/atoms';
import styles from './servicesSlider.module.scss';
import { Slider, ServicesBadge } from '@/components/molecules';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const ServicesSlider = () => {
  const { isDesktop } = useDeviceType();
  const ribbonBenefits = [
    {
      id: '0',
      label: 'Envíos a todo el país',
      icon: <BsTruck size={50} />
    },
    {
      id: '1',
      label: '3 cuotas sin interés',
      icon: <BsCreditCard size={50} />
    },
    {
      id: '2',
      label: 'Promociones y descuentos',
      icon: <FaPercent size={20} />
    },
    {
      id: '3',
      label: 'Compras seguras',
      icon: <BiLockAlt size={50} />
    }
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Text
          textColor="white"
          className={styles.text}
        >
          Servicios
        </Text>
        <Divider
          color="var(--color-grey-light)"
          style={{ margin: 'auto' }}
        />
      </div>
      {!isDesktop ? (
        <Slider withoutChevrons>
          {ribbonBenefits.map((r) => (
            <ServicesBadge
              title={r.label}
              key={r.id}
              icon={r.icon}
            />
          ))}
        </Slider>
      ) : (
        <div className={styles.desktop}>
          {ribbonBenefits.map((r) => (
            <ServicesBadge
              title={r.label}
              key={r.id}
              icon={r.icon}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ServicesSlider;
